function loadGaScript(gaTrackingId, onLoad, onError) {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
  script.onload = onLoad;
  script.onerror = onError;
  document.head.appendChild(script);
}

function odgainit(gaTrackingId, onSuccess, onError)  {
  loadGaScript(gaTrackingId, () => {
    try {
      // userd for initialization gtag
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      // setup consent mode, need to add config in termly consent manager, must config first before otthers
      const adStorageConsent = Cookies.get("_ad_consent_ad_storage");
      const adUserDataConsent = Cookies.get("_ad_consent_user_data");
      const adPersonalizationConsent = Cookies.get("_ad_consent_personalization");
      const adAnalyticsStorageConsent = Cookies.get("_ad_consent_analytics_storage");

      gtag("consent", "default", {
        ad_storage: adStorageConsent ? adStorageConsent: "granted",
        ad_user_data: adUserDataConsent ? adUserDataConsent: "granted",
        ad_personalization: adPersonalizationConsent ? adPersonalizationConsent : "granted",
        analytics_storage: adAnalyticsStorageConsent ? adAnalyticsStorageConsent : "granted",
        wait_for_update: 500,
      });
      gtag("set", "ads_data_redaction", true);
      if (!adStorageConsent) {
        Cookies.set("_ad_consent_ad_storage", "granted", { expires: 365, domain: "dealomega.com", });
      }
      if (!adUserDataConsent) {
        Cookies.set("_ad_consent_user_data", "granted", { expires: 365, domain: "dealomega.com", });
      }
      if (!adPersonalizationConsent) {
        Cookies.set("_ad_consent_personalization", "granted", { expires: 365, domain: "dealomega.com", });
      }
      if (!adAnalyticsStorageConsent) {
        Cookies.set("_ad_consent_analytics_storage", "granted", { expires: 365, domain: "dealomega.com", });
      }
      gtag('js', new Date());
      gtag('config', gaTrackingId);
      // Attach gtag to the global window object
      if(window && !window.gtag) {
        window.gtag = gtag;
      }
      onSuccess();
    } catch(error) {
      onError(error);
    }
  },
  (error) => {
    onError(error);
  });
}

function login(callback) {
  if (!window || typeof window.gtag !== 'function') {
    throw new Error('gtag is not defined');
  }
  window.gtag("event", "login",{
    'event_callback': callback
  });
}

function selectItem(product_event) {
  if (!window || typeof window.gtag !== 'function') {
    throw new Error('gtag is not defined');
  }
  window.gtag("event", "select_item", product_event);
}

function viewItem(product_event) {
  if (!window || typeof window.gtag !== 'function') {
    throw new Error('gtag is not defined');
  }
  window.gtag("event", "view_item", product_event);
}

function addToCart(variant_event) {
  if (!window || typeof window.gtag !== 'function') {
    throw new Error('gtag is not defined');
  }
  window.gtag("event", "add_to_cart", variant_event);
}

function beginCheckout(order_evenet, callback) {
  if (!window || typeof window.gtag !== 'function') {
    throw new Error('gtag is not defined');
  }
  window.gtag("event", "begin_checkout", {
    ...order_evenet,
    'event_callback': callback
  });
}


function addShippingInfo(order_evenet, callback) {
  if (!window || typeof window.gtag !== 'function') {
    throw new Error('gtag is not defined');
  }
  window.gtag("event", "add_shipping_info", {
    ...order_evenet,
    'event_callback': callback
  });
}

function purchase(order_evenet, callback) {
  if (!window || typeof window.gtag !== 'function') {
    throw new Error('gtag is not defined');
  }
  window.gtag("event", "purchase", {
    ...order_evenet,
    'event_callback': callback
  });
}

function setEmail(emailHash) {
  if (!window || typeof window.gtag !== 'function') {
    throw new Error('gtag is not defined');
  }
  window.gtag("set", "user_data", {
    sha256_email_address: emailHash,
  });
}
